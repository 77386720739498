import React from 'react'

const Footer = props => (
  <footer id="footer">
    <section>
      <h2>Woohoo</h2>
      <p>We can't wait to celebrate with you!</p>
    </section>
    <section>
      <dl className="alt">
        <dt>Address</dt>
        <dd>
          <a
            href="https://goo.gl/maps/FkvJNH5KpAEjWsCF8"
            target="_blank"
            rel="noopener noreferrer"
          >
            15903 SE Oatfield Road
            <br />
            Oak Grove, OR 97267
          </a>
        </dd>
        <dt>Phone</dt>
        <dd>
          <a href="tel:5035447969">(503) 544-7969</a>
        </dd>
        <dt>Email</dt>
        <dd>
          <a href="mailto:wendi.y.anderson@gmail.com">
            wendi.y.anderson@gmail.com
          </a>
        </dd>
      </dl>
    </section>
    <p className="copyright">
      &copy;{' '}
      <a
        href="https://www.wanderingwonton.me/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Wendi
      </a>
      &amp;{' '}
      <a
        href="https://generativeartist.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Lucas
      </a>{' '}
      2019
    </p>
  </footer>
)

export default Footer
